'use client'

import { useEffect } from 'react'
import { isAddress } from 'viem'
import { useAccount, useChains } from 'wagmi'
import { ConnectorName } from 'wallet'
import { useSearchParams } from 'next/navigation'
import analytics from 'analytics'


import getWalletName from './setWalletNameToMixpanel'


export const useWallet = () => {
  const { address, connector, isConnected, isConnecting, isReconnecting, chain, chainId } = useAccount()
  const chains = useChains()
  const isWalletConnect = connector?.name === ConnectorName.WalletConnect

  const searchParams = useSearchParams()
  const spyAcc = searchParams.get('inspect') || ''

  useEffect(() => {
    if (!connector) {
      return
    }

    analytics.setSuperProps({
      wallet_type: connector?.name,
      wallet_name: getWalletName(connector),
    })
    analytics.track('wallet connect success')
  }, [ connector ])

  return {
    account: isAddress(spyAcc) ? spyAcc : address,
    connector,
    chain,
    chains,
    chainId,
    isConnected,
    isConnecting,
    isReconnecting,
    isWalletConnect,
  }
}
