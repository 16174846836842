export default {
  okay: {
    en: 'Okay',
  },
  cancel: {
    en: 'Cancel',
  },
  close: {
    en: 'Close',
  },
  connectWallet: {
    en: 'Connect wallet',
  },
  connectWalletDynamic: {
    en: 'Connect<span class="-sm:hidden"> wallet</span>',
  },
  connect: {
    en: 'Connect',
  },
  addLiquidity: {
    en: 'Add Liquidity',
  },
  approve: {
    en: 'Approve',
  },
  buildersHub: {
    en: 'Builder’s hub',
  },
  confirm: {
    en: 'Confirm',
  },
  processing: {
    en: 'Processing',
  },
  continue: {
    en: 'Continue',
  },
  start: {
    en: 'Start',
  },
  build: {
    en: 'Build',
  },
  explore: {
    en: 'Explore Apps',
  },
  learnMore: {
    en: 'Learn more',
  },
  goApp: {
    en: 'Go to App',
  },
  launchPortal: {
    en: 'Go to Portal',
  },
  goHome: {
    en: 'Go to home',
  },
  tryAgain: {
    en: 'Try again',
  },
  getStarted: {
    en: 'Get started',
  },
  viewTransaction: {
    en: 'View Transaction In Explorer',
  },
  myOrders: {
    en: 'My Orders',
  },
  withdraw: {
    en: 'Withdraw',
  },
  reset: {
    en: 'Reset',
  },
  apply: {
    en: 'Apply',
  },
  stake: {
    en: 'Stake',
  },
  details: {
    en: 'Details',
  },
  unstake: {
    en: 'Unstake',
  },
  claim: {
    en: 'Claim',
  },
  claimed: {
    en: 'Claimed',
  },
  switchChain: {
    en: 'Switch chain to {name}',
  },
}
