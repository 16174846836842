import { sendGTMEvent } from '@next/third-parties/google'


const track = <T>(eventName: string, props?: SuperProps<T>) => {
  sendGTMEvent({
    event: eventName,
    ...(props || {}),
  })
}

type FilterEventKey<T, K extends keyof T> = K extends 'event' ? never : T[K]

type SuperProps<T, Keys extends keyof T = keyof T> = {
  [K in Keys]: FilterEventKey<T, K>
}

/**
 * Set up dynamic props which will be available to all next events fired by "track".
 * props accepts any key name except "event" (to separate and clarify).
 */
const setSuperProps = <T>(props: SuperProps<T>) => {
  /*
  * Variables in GTM are dynamic!
  * Something like "super props" in mixpanel - set up values to context,
  * but which of them must be attached to events - area of Analytics team control in Tag Manager triggers
  *
  * From https://developers.google.com/tag-platform/tag-manager/datalayer#use_a_data_layer_with_event_handlers
  * You can push data layer variables to the data layer dynamically to capture information such as values entered or selected in a form,
  * metadata associated with a video that the visitor is playing, the color of a product (e.g. a car) customized by the visitor,
  * the destination URLs of clicked links, etc.
  *
  * As with events, this functionality is accomplished by calling the push() API to add or replace data layer variables in the data layer.
  * The basic syntax for setting dynamic data layer variables is as follows:
  * dataLayer.push({'variable_name': 'variable_value'});
  * */
  sendGTMEvent(props)
}

export default {
  track,
  setSuperProps,
}
