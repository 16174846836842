'use client'
import type { MouseEventHandler } from 'react'
import clsx from 'clsx'

import { Href, type HrefProps } from 'components/navigation'


export type LandButtonStyle = 'land-black' | 'land-white' | 'land-gradient-ocean' | 'land-ocean-20'
export type AppButtonStyle = 'master-primary' | 'primary' | 'secondary' | 'tertiary' | 'quaternary'

type StylesMap = Record<LandButtonStyle | AppButtonStyle, string>

const styles: StylesMap = {
  // Landing styles
  'land-black': 'btn-land-black',
  'land-white': 'btn-land-white',
  'land-gradient-ocean': 'btn-land-gradient-ocean',
  'land-ocean-20': 'btn-land-ocean-20',
  // App styles
  'master-primary': 'btn-master-primary',
  'primary': 'btn-primary',
  'secondary': 'btn-secondary',
  'tertiary': 'btn-tertiary',
  'quaternary': 'btn-quaternary',
}

const btnLandSizeClassNameMap = {
  40: 'btn-land-size-40',
  48: 'btn-land-size-48',
  56: 'btn-land-size-56',
}

const btnAppSizeClassNameMap = {
  20: 'btn-size-20',
  32: 'btn-size-32',
  44: 'btn-size-44',
}

export type LandingSizedProps = {
  styling: LandButtonStyle | null
  size: 40 | 48 | 56 | null
}

export type AppSizedProps = {
  styling: AppButtonStyle | null
  size: 20 | 32 | 44 | null
}

export type ButtonBaseProps = Omit<HrefProps, 'title' | 'style' | 'message' | 'onClick'> & {
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | null
} & (LandingSizedProps | AppSizedProps)

const ButtonBase: React.CFC<ButtonBaseProps> = (props) => {
  const { children, className, styling, size, disabled, loading, onClick, ...rest } = props

  const isLink = Boolean(rest.to || rest.toTab || rest.href)

  const btnSizeClassName = size
    ? styling?.startsWith('land-') ? btnLandSizeClassNameMap[size] : btnAppSizeClassNameMap[size]
    : null

  const rootClassName = clsx(className, styling && styles[styling], btnSizeClassName, {
    'pointer-events-none disabled': loading || disabled,
  })

  const handleClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (event) => {
    if (disabled || loading) {
      event.preventDefault()

      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  if (isLink) {
    return (
      <Href
        className={rootClassName}
        role="button"
        onClick={handleClick}
        aria-disabled={disabled}
        aria-busy={loading}
        {...rest}
      >
        {children}
      </Href>
    )
  }

  return (
    <button
      className={rootClassName}
      type="button"
      disabled={disabled || loading}
      aria-busy={loading}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export default ButtonBase
