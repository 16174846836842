import localStorage from '@locmod/local-storage'
import { type Connector } from 'wagmi'
import { ConnectorName } from 'wallet'


const getWalletName = (connector: Connector) => {
  const windowEth = window.ethereum
  let walletName: string = ''

  if (connector?.name === ConnectorName.WalletConnect) {
    // TODO: it's not stored in localStorage, rework to IndexedDB
    const [ session ] = localStorage.getItem('wc@2:client:0.3//session') || []

    walletName = session?.peer?.metadata?.name || connector?.name
  }
  else if (!windowEth || !connector?.name) {
    return
  }
  else {
    if (windowEth.isTrust) {
      walletName = 'TrustWallet'
    }

    if (windowEth.isGoWallet) {
      walletName = 'GoWallet'
    }

    if (windowEth.isAlphaWallet) {
      walletName = 'AlphaWallet'
    }

    if (windowEth.isStatus) {
      walletName = 'Status'
    }

    if (windowEth.isToshi) {
      walletName = 'Coinbase'
    }

    if (windowEth.isOneInchIOSWallet || windowEth.isOneInchANDROIDWallet) {
      walletName = '1inch'
    }

    if (!walletName) {
      walletName = connector.name
    }

    if (windowEth.isMetaMask) {
      walletName = 'MetaMask'
    }
  }

  return walletName
}

export default getWalletName
