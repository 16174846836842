import { http, createConfig, cookieStorage, createStorage } from 'wagmi'
import { injected, walletConnect } from 'wagmi/connectors'
import { mainnet, arbitrum, arbitrumGoerli, polygon, polygonAmoy, gnosis, lineaTestnet, linea, chiliz, type Chain } from 'wagmi/chains'
import { constants } from 'helpers'
import tokenAzuroImage from 'public/images/icons/tokens/azuro.svg?url'
import iconAzuroImage from 'src/app/icon.png'


const isDev = constants.isDev
const injectedConnector = injected({ shimDisconnect: true, unstable_shimAsyncInject: true })


const chilizTestnet = {
  blockExplorers: {
    default: {
      name: 'ChiliScan',
      url: 'https://testnet.chiliscan.com',
    },
  },
  id: 88882,
  name: 'Chiliz Spicy Testnet',
  nativeCurrency: {
    name: 'CHZ',
    symbol: 'CHZ',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: [ 'https://spicy-rpc.chiliz.com' ],
    },
    public: {
      http: [ 'https://chiliz-spicy-rpc.publicnode.com' ],
    },
  },
  testnet: true,
} as const satisfies Chain

export const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_ID as string

const walletConnectConnector = walletConnect({
  projectId,
  metadata: {
    name: 'Azuro protocol',
    description: 'Azuro – The Predictions Layer',
    icons: [ `${constants.baseUrl}/${tokenAzuroImage.src}`, `${constants.baseUrl}/${iconAzuroImage.src}`, 'https://azuro.org/images/favicon/android-chrome-512x512.png' ],
    url: isDev ? 'https://dev.azuro.org' : 'https://azuro.org',
  },
  showQrModal: true,
  qrModalOptions: {
    themeMode: 'light',
    privacyPolicyUrl: constants.links.privacy,
    termsOfServiceUrl: constants.links.terms,
    themeVariables: {
      '--wcm-z-index': '1005',
      '--wcm-accent-color': '#3d67ff',
      '--wcm-accent-fill-color': '#fff',
      '--wcm-container-border-radius': '1.5rem',
      '--wcm-background-border-radius': '1.5rem',
      // @ts-ignore they are handled but don't exist in types
      '--wcm-success-color': '#15b672',
      '--wcm-error-color': '#ee2424',
    },
  },
})

const wagmiConfig = isDev
  ? createConfig({
    chains: [ mainnet, polygon, polygonAmoy, gnosis, arbitrumGoerli, lineaTestnet, chilizTestnet ],
    transports: {
      [mainnet.id]: http(constants.rpcByChains[mainnet.id]),
      [polygon.id]: http(constants.rpcByChains[polygon.id]),
      [polygonAmoy.id]: http(constants.rpcByChains[polygonAmoy.id]),
      [gnosis.id]: http(constants.rpcByChains[gnosis.id]),
      [arbitrumGoerli.id]: http(constants.rpcByChains[arbitrumGoerli.id]),
      [lineaTestnet.id]: http(constants.rpcByChains[lineaTestnet.id]),
      [chilizTestnet.id]: http(constants.rpcByChains[chilizTestnet.id]),
    },
    connectors: [
      injectedConnector,
      walletConnectConnector,
    ],
    ssr: true,
    syncConnectedChain: true,
    multiInjectedProviderDiscovery: true,
    batch: {
      multicall: {
        wait: 8,
      }
    },
    storage: createStorage({
      storage: cookieStorage,
    }),
  })
  : createConfig({
    chains: [ mainnet, polygon, gnosis, arbitrum, linea, chiliz ],
    transports: {
      [mainnet.id]: http(constants.rpcByChains[mainnet.id]),
      [polygon.id]: http(constants.rpcByChains[polygon.id]),
      [gnosis.id]: http(constants.rpcByChains[gnosis.id]),
      [arbitrum.id]: http(constants.rpcByChains[arbitrum.id]),
      [linea.id]: http(constants.rpcByChains[linea.id]),
      [chiliz.id]: http(constants.rpcByChains[chiliz.id]),
    },
    connectors: [
      injectedConnector,
      walletConnectConnector,
    ],
    ssr: true,
    syncConnectedChain: true,
    multiInjectedProviderDiscovery: true,
    storage: createStorage({
      storage: cookieStorage,
    }),
  })

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig
  }
}

export default wagmiConfig
