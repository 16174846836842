'use client'
import { type ReactElement, type ReactNode } from 'react'
import clsx from 'clsx'
import { Message } from '@locmod/intl'

import { Icon, type IconProps } from 'components/ui'
import { ButtonBase, type ButtonBaseProps } from 'components/inputs'

import messages from './messages'


export type ButtonProps = ButtonBaseProps & {
  title: string | Intl.Message
  leftIcon?: IconProps['name'] | null
  rightIcon?: IconProps['name'] | null
  leftNode?: ReactElement | null
  rightNode?: ReactElement | null
  loadingRightSide?: boolean
  inline?: boolean
}

const Button: React.FC<ButtonProps> = (props) => {
  let { className, title, leftIcon, rightIcon, leftNode, rightNode, loading, loadingRightSide = true, inline = true, onClick, ...rest } = props

  const rootClassName = clsx(className, 'items-center justify-center whitespace-nowrap', inline ? 'inline-flex' : 'flex')

  let leftIconNode: ReactNode
  let rightIconNode: ReactNode

  if (leftIcon || leftNode || (loading && !loadingRightSide)) {
    leftIconNode = loading
      ? <Icon className="btn-icon" name="interface/spinner" />
      : leftIcon && <Icon className="btn-icon" name={leftIcon} /> || leftNode
  }

  if (rightIcon || (loading && loadingRightSide)) {
    rightIconNode = <Icon className="btn-icon" name={loading ? 'interface/spinner' : rightIcon!} />
  }

  let buttonTitle = title

  if (loading) {
    buttonTitle = messages.processing
  }

  return (
    <ButtonBase
      className={rootClassName}
      loading={loading}
      onClick={onClick}
      {...rest}
    >
      {leftIconNode}
      <Message value={buttonTitle} html />
      {rightIconNode}
      {rightNode}
    </ButtonBase>
  )
}

export default Button
